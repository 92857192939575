import React from 'react';
import Layout from '../components/Layout.js';
import Head from '../components/head';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$/));

class UniversoExpand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {mainImage: images["UniversoShowcase01.jpg"]}
    this.changeImage = this.changeImage.bind(this)
  }

  changeImage(e) {
    console.log(e.target.src)
    this.setState({mainImage: e.target.src})
  }

  render() {
    return (
      <Layout>
      <Head title="Tocando el universo" />
      <div className="showcase-container">
        <div className="main-image-container">
          <img src={this.state.mainImage} />
        </div>
        <div className="bottom-images-container">
          <img onClick={this.changeImage} src={images["UniversoShowcase01.jpg"]} />
          <img onClick={this.changeImage} src={images["UniversoShowcase02.jpg"]} />
          <img onClick={this.changeImage} src={images["UniversoShowcase03.jpg"]} />
          <img onClick={this.changeImage} src={images["UniversoShowcase04.jpg"]} />
          <img onClick={this.changeImage} src={images["UniversoShowcase05.jpg"]} />
        </div>
      </div>
      <h2 className="tituloDeLibroFullScreen">Tocando el universo</h2>
        <section className="wideExpandTextoSection">
        <p className="section-text">Acompaña a Juan y Citlali en este emocionante e interesante viaje por el universo. Vive con ellos esta aventura interactiva, animada y con audio. Visitaras el Sol, la Luna, un campo de asteroides y muchas zonas más de la galaxia.
        De la colección Gusano de Luz, de Editorial Porrúa.
        </p>
      </section>

      <p className="section-text">Próximamante en iBooks Store.</p>
      </Layout>
    );
  }
}

export default UniversoExpand;
